// src/components/Footer.js
import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="body2" color="textSecondary" align="center">
                    © {new Date().getFullYear()} Orders.co. All rights reserved. |{' '}
                    <Link href="https://orders.co/privacy-policy/" target="_blank" rel="noopener">
                        Privacy Policy
                    </Link>{' '}
                    |{' '}
                    <Link href="https://orders.co/terms-of-use" target="_blank" rel="noopener">
                        Terms of Service
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
