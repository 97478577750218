import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Box,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';

const provisioningUrl = 'https://gateway.orders.co/provisioning/';
const provisioningToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3MjFmM2M3YzZlMzI5MDcyYWIyYTU0NiIsInNlc3Npb25JZCI6ImU4YTAxZWIzLWYxOTQtNDgzYi04MzJjLWM5OTZkZmNhZTRkYyIsImlhdCI6MTczMDM5MDA2MX0.PqdQRgypNKpCmE5eIe5zA7LquJjVs_c49o6FWgwkiEs'
const provisioningBusinessId = '6721f3e3c6e329072ab2a56a';

function Completion() {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(
        'Please wait while we set up your store. It may take up to a minute.'
    );
    const [messageSeverity, setMessageSeverity] = useState('info');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    useEffect(() => {

        const auth = JSON.parse(localStorage.getItem('auth'));
        const businessInfo = JSON.parse(localStorage.getItem('businessInfo'));
        const addressInfo = JSON.parse(localStorage.getItem('addressInfo'));
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!businessInfo || !addressInfo || !userInfo) {
            Navigate('/business-info');
            return;
        }

        const data = {
            businessInfo,
            addressInfo,
            userInfo,
            provisionInfo: {
                provisionLocationId: addressInfo.placeId,
                provisionLocationName: businessInfo.name,
                accessToken: auth.accessToken,
                refreshToken: auth.refreshToken,
                paymentTerm: 'monthly',
                provider: 'wix',
            },
        };



        const handleSuccess = () => {
            setLoading(false);
            setMessage(
                'Congratulations! Your store setup is complete. Please check your email to log in to your dashboard.'
            );
            setMessageSeverity('success');
            setOpenSnackbar(true);
        };

        const handleError = (error) => {
            console.error(error);
            setLoading(false);
            setMessage(
                'We are unable to complete the setup. Please contact support at 855-500-1070 option 4 to finish the onboarding.'
            );
            setMessageSeverity('error');
            setOpenSnackbar(true);
        };

        if (isMock) {
            // Simulate API call with mock data
            setTimeout(() => {
                const isSuccess = true; // Set to false to simulate error
                if (isSuccess) {
                    handleSuccess();
                } else {
                    handleError(new Error('Provisioning failed'));
                }
            }, 3000);
        } else {
            axios
                .post(provisioningUrl, data, {
                    headers: {
                        Authorization: `Bearer ${provisioningToken}`,
                        'Content-Type': 'application/json',
                        business: provisioningBusinessId, // Adjust as needed
                    },
                })
                .then(handleSuccess)
                .catch(handleError);
        }
    }, [isMock]);

    return (
        <Container maxWidth="sm">
            <Box textAlign="center" mt={10}>
                {loading ? (
                    <>
                        <CircularProgress />
                        <Typography variant="h6" mt={2}>
                            {message}
                        </Typography>
                    </>
                ) : (
                    <Typography variant="h6" mt={2}>
                        {message}
                    </Typography>
                )}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={null}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity={messageSeverity}
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default Completion;
