// src/components/LocationSelection.js
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    Grid,
    Snackbar,
    Alert,

} from '@mui/material';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Custom styled components
const StyledCard = styled(Card)(({ theme, selected }) => ({
    cursor: 'pointer',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: selected
        ? `0 4px 20px ${theme.palette.primary.main}`
        : '0 2px 8px rgba(0,0,0,0.1)',
    transform: selected ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        boxShadow: `0 4px 20px ${theme.palette.primary.light}`,
        transform: 'scale(1.05)',
    },
}));

const GradientBackground = styled('div')(({ theme }) => ({
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

function LocationSelection() {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    const locations = JSON.parse(localStorage.getItem('locations'));
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleNext = () => {
        if (selectedLocation) {
            localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
            navigate(`/business-info${isMock ? '?mock=true' : ''}`); // Updated route
        } else {
            setErrorMessage('Please select a location to proceed.');
            setOpenSnackbar(true);
        }
    };

    return (
        <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Box mt={5} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    Welcome to Orders.co WIX Setup
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Select a Location to Connect
                </Typography>
            </Box>
            <Grid container spacing={4} justifyContent="center" mt={3}>
                {locations.map((location) => (
                    <Grid item xs={12} sm={6} key={location.id}>
                        <StyledCard
                            onClick={() => setSelectedLocation(location)}
                            selected={selectedLocation?.id === location.id}
                        >
                            <GradientBackground>
                                <LocationOnIcon style={{ fontSize: 60, color: '#fff' }} />
                            </GradientBackground>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {location.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {location.address.formattedAddress}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
            <Box mt={3} textAlign="right">
                <Button variant="contained" onClick={handleNext}
                    disabled={!selectedLocation} // Disable button if no location is selected                
                >
                    Next
                </Button>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LocationSelection;
