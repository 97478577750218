// src/components/Overview.js
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Grid,
    Divider,
} from '@mui/material';

function Overview() {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    // Retrieve data from localStorage
    const businessInfo = JSON.parse(localStorage.getItem('businessInfo'));
    const addressInfo = JSON.parse(localStorage.getItem('addressInfo'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    // Redirect if any data is missing
    if (!businessInfo || !addressInfo || !userInfo) {
        navigate('/business-info');
        return null;
    }

    const handleProceed = () => {
        navigate(`/complete${isMock ? '?mock=true' : ''}`);
    };

    const handleBack = () => {
        navigate(`/userinfo${isMock ? '?mock=true' : ''}`);
    };

    return (
        <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Typography variant="h4" gutterBottom>
                Overview
            </Typography>

            {/* Business Information */}
            <Typography variant="h6" gutterBottom>
                Business Information
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Business Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{businessInfo.name}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Phone Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{businessInfo.phone}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Preparation Time:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{`${businessInfo.prepTime} minutes`}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Address Information */}
            <Typography variant="h6" gutterBottom>
                Address Information
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Address Line 1:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{addressInfo.address1}</Typography>
                </Grid>

                {addressInfo.address2 && (
                    <>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Address Line 2:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">{addressInfo.address2}</Typography>
                        </Grid>
                    </>
                )}

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>City:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{addressInfo.city}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>State:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{addressInfo.state}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Zip Code:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{addressInfo.zipCode}</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* User Information */}
            <Typography variant="h6" gutterBottom>
                User Information
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>First Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{userInfo.firstName}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Last Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{userInfo.lastName}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{userInfo.email}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Phone:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1">{userInfo.phone}</Typography>
                </Grid>
            </Grid>

            <Box mt={3} textAlign="right">
                <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ mr: 2 }}
                >
                    Back
                </Button>
                <Button variant="contained" onClick={handleProceed}>
                    Proceed
                </Button>
            </Box>
        </Box>
    );
}

export default Overview;
